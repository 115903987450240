<script setup>
  import { ref, computed } from 'vue';

  import ProductCard from '../components/ProductCard.vue';
  import { useWishlistStore } from "../stores/wishlist";
  import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

  const webshopSlug = ref(webshopSlugRef);
  const wishlistStore = useWishlistStore();

  const props = defineProps({
      title: String,
      showLink: Boolean,
  });
</script>

<template>
  <div class="breadcrumbs">
    <ul class="breadcrumbs__list">
      <li class="breadcrumbs__item breadcrumbs__item--back">
        <a :href="appendLanguagePrefixURL(webshopSlug)">Verder winkelen</a>
      </li>
    </ul>
  </div>
  <section class="wishlist-main" aria-labelledby="wishlist-main__title">
    <div class="wishlist-main__header">
      <h1 id="wishlist-main__title">Mijn verlanglijst</h1> <!-- HARDCODED TEXT -->
    </div>

    <div class="wishlist-main__items" v-if="wishlistStore.wishlist.products && wishlistStore.wishlist.products.length === 0">
        <p>Je verlanglijst is leeg</p>  <!-- HARDCODED TEXT -->
    </div>
    <div class="wishlist-main__items" v-else>
        <ProductCard
            v-for="product in wishlistStore.wishlist.products"
            :product="product"
            :show-skeleton=false
        />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.wishlist-main {
  @apply container;
  @apply lg:px-6;
  @apply sm:px-4 sm:mb-6;
}

.wishlist-main__items {
    > p {
      @apply col-span-12;
    }
}

h1 {
  @apply font-fontText font-bold text-32_32_20_20 text-dark mb-7;
  @apply sm:mb-4;
}
</style>
